import { AttendeeAndPresenterProvider } from '../context/AttendeeAndPresenterProvider';
import Controls from './Controls';
import QuestionComponent from './QuestionComponent';

const Presenter = ({ isReadOnly }) => {
  return (
    <AttendeeAndPresenterProvider>
      <div className="flex-center direction-column" style={{ padding: 50 }}>
        <QuestionComponent isReadOnly={isReadOnly} />
        {!isReadOnly && <Controls />}
      </div>
    </AttendeeAndPresenterProvider>
  );
};

export default Presenter;
