import { useEffect, useState } from 'react';
import CustomGameButton from '../common/CustomGameButton';

const ictLogo = require('../../assets/ict_logo.png');

const QuestionComponent = ({ isIdleOrEnded, currentQuestion, gameState, submitResponse }) => {
  const [userAnswer, setUserAnswer] = useState(null);

  useEffect(() => {
    setUserAnswer(null);
  }, [currentQuestion]);

  // if (!currentQuestion) {
  //   return <h1>Loading...</h1>;
  // }

  const handleClick = (answer) => {
    if (userAnswer) return;
    setUserAnswer(answer);
    submitResponse(answer.id, currentQuestion.id);
  };

  return (
    <div style={{ textAlign: 'center', height: '100%' }}>
      <img src={ictLogo} alt="ICT Logo" style={{ width: '80%', marginTop: 15, marginBottom: 15, marginLeft: -20 }} />
      <h2 style={{ marginBottom: 10, fontWeight: 900, fontSize: '3vh' }}>Bipolar I or Bipolar II?</h2>

      {isIdleOrEnded ? (
        <h1 style={{ textAlign: 'center', marginTop: 125 }}>
          {gameState.state === 'ended' ? 'Thank you for participating!' : 'Questions will appear shortly.'}
        </h1>
      ) : (
        <div
          className="flex direction-column"
          style={{
            justifyContent: 'space-evenly',
            height: '80%'
          }}
        >
          <h2
            style={{ marginBottom: 10, fontSize: '3vh', fontWeight: 500 }}
            dangerouslySetInnerHTML={{ __html: currentQuestion.text }}
          />

          <div>
            {currentQuestion.answers.map((answer, index) => {
              return (
                <CustomGameButton
                  key={index}
                  text={answer.text}
                  isCorrect={userAnswer && userAnswer.isCorrect}
                  isSelected={userAnswer && userAnswer.id === answer.id}
                  onClick={() => handleClick(answer)}
                />
              );
            })}
          </div>
          {userAnswer ? (
            <h2 style={{ textAlign: 'center', height: 20, fontSize: '3vh' }}>
              {userAnswer.isCorrect ? 'Correct!' : 'Incorrect.'}
            </h2>
          ) : (
            <div style={{ height: 40 }} />
          )}
        </div>
      )}
    </div>
  );
};

export default QuestionComponent;
