import { usePresenterOrAttendee } from '../context/AttendeeAndPresenterProvider';
import { LinearProgress } from '@mui/material';

const QuestionComponent = ({ isReadOnly }) => {
  const { currentQuestion, userAnswers, gameState } = usePresenterOrAttendee();

  // console.log('currentQuestion', currentQuestion, 'userAnswers', userAnswers);

  if (!currentQuestion) {
    if (gameState.state === 'initial') {
      return <h1>Waiting for game to start...</h1>;
    }
    return '';
  }

  return (
    <div>
      <h2 dangerouslySetInnerHTML={{ __html: currentQuestion.text }} />
      <h4>Total answers: {userAnswers.totalAnswerCount || 0}</h4>

      {currentQuestion.answers.map((answer, index) => {
        const percentage = userAnswers?.[answer.id] || 0;
        return (
          <div key={index}>
            <h3>
              {answer.text} ({percentage}%)
            </h3>
            <LinearProgress variant="determinate" value={percentage} />
          </div>
        );
      })}
    </div>
  );
};

export default QuestionComponent;
