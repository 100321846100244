import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';

const QuestionComponent = ({ isIdleOrEnded, currentQuestion, gameState, submitResponse }) => {
  const [userAnswer, setUserAnswer] = useState(null);

  // console.log('currentQuestion', currentQuestion, questions);

  useEffect(() => {
    setUserAnswer(null);
  }, [currentQuestion]);

  const getClass = (answerId) => {
    if (userAnswer && userAnswer.id === answerId) {
      return userAnswer.isCorrect ? 'branaded-correct-button' : 'branaded-incorrect-button';
    }
    return '';
  };

  const getCorrectIncorrectText = () => {
    if (userAnswer) {
      if (userAnswer.isCorrect) {
        return 'Correct!';
      }
      return 'Incorrect.';
    }
    return '';
  };

  return (
    <div className="flex direction-column" style={{ textAlign: 'center', height: '100%' }}>
      {gameState.state !== 'ended' && <h2 style={{ padding: '30px 0px 0px 15px', margin: 0 }}>What's Your Number?</h2>}

      {isIdleOrEnded ? (
        <h1 style={{ textAlign: 'center', marginTop: 125 }}>
          {gameState.state === 'ended' ? 'Thank you for participating!' : 'Questions will appear shortly.'}
        </h1>
      ) : (
        <div className="flex direction-column">
          <h2 style={{ textAlign: 'center', height: 5 }}>{getCorrectIncorrectText()}</h2>
          <Grid container gutter={3} spacing={1.5} sx={{ width: '75%', margin: '0 auto' }}>
            {currentQuestion.answers.map((answer, index) => {
              return (
                <Grid item xs={6} key={index}>
                  <button
                    type="button"
                    className={`branded-button ${getClass(answer.id)}`}
                    onClick={() => {
                      if (userAnswer) {
                        return;
                      }
                      setUserAnswer(answer);
                      submitResponse(answer.id, currentQuestion.id);
                    }}
                  >
                    <span className="branded-button-inner-text">{answer.text}</span>
                  </button>
                </Grid>
              );
            })}
          </Grid>
          <div
            style={{
              justifyContent:
                (!userAnswer && currentQuestion.infoBox) || !currentQuestion.infoBox ? 'center' : 'space-evenly'
            }}
            className="flex direction-column"
          >
            {/* <p style={{ height: 10 }}>
              {!userAnswer && currentQuestion.infoBox && <span>{currentQuestion.infoBox}</span>}
            </p>
            <h2 style={{ fontSize: '2.6vh', fontWeight: 500 }}>{currentQuestion.text}</h2> */}

            <div>
              {userAnswer && currentQuestion.infoBox && (
                <span style={{ fontSize: '2vh' }} dangerouslySetInnerHTML={{ __html: currentQuestion.infoBox }} />
              )}
            </div>
            <div
              style={{ fontSize: '2.6vh', fontWeight: 500 }}
              dangerouslySetInnerHTML={{ __html: currentQuestion.text }}
            />
          </div>
        </div>
      )}
      {gameState.state !== 'ended' && (
        <p
          style={{
            textAlign: 'center',
            width: '95%',
            position: isIdleOrEnded ? 'absolute' : null,
            paddingBottom: isIdleOrEnded ? 0 : 15,
            bottom: 15,
            fontSize: '1.8vh'
          }}
        >
          Please see Important Safety Information, <br /> including Boxed Warnings,
          <br /> throughout this presentation.
        </p>
      )}
    </div>
  );
};

export default QuestionComponent;
