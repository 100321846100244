// import { useState } from 'react';

import { usePresenterOrAttendee } from '../context/AttendeeAndPresenterProvider';
import { Button } from '@mui/material';

const buttonStyle = {
  width: 200,
  padding: 1
};

const Controls = () => {
  const { gameState, startGame, endGame, nextQuestion, closeQuestion, prevQuestion, openQuestion, resetGame } =
    usePresenterOrAttendee();

  if (!gameState || !gameState.state) {
    return <div>Loading Data...</div>;
  }

  // console.log('gameState', gameState);
  const getButtonAction = () => {
    // console.log('AAAAAAACtion', 'prevQuestion', prevQuestion, 'gameState.questionId', gameState.questionId);

    if (prevQuestion === 'initial' && gameState.questionId && gameState.questionState === 'closed') {
      // setprevQuestion(gameState.questionId);
      return openQuestion;
    }

    if (['closed', 'new'].includes(gameState.questionState)) {
      return prevQuestion && gameState.questionId && prevQuestion !== gameState.questionId
        ? openQuestion
        : nextQuestion;
    } else if (gameState.questionState === 'open') {
      return closeQuestion;
    }
  };

  const getButtonLabel = () => {
    // console.log('LLLLLABBBBEEEELLLLL', 'prevQuestion', prevQuestion, 'gameState.questionId', gameState.questionId);

    if (prevQuestion === 'initial' && gameState.questionId && gameState.questionState === 'closed') {
      return 'Open Question';
    }

    if (['closed', 'new'].includes(gameState.questionState)) {
      return prevQuestion && gameState.questionId && prevQuestion !== gameState.questionId
        ? 'Open Question'
        : 'Next Question';
    } else if (gameState.questionState === 'open') {
      return 'Close Question';
    }
  };

  return (
    <div className="flex-center direction-column" style={{ height: 300, justifyContent: 'space-evenly' }}>
      <Button variant="contained" onClick={startGame} disabled={gameState.state !== 'initial'} sx={buttonStyle}>
        Start Game
      </Button>
      <Button
        variant="contained"
        onClick={getButtonAction()}
        disabled={gameState.state !== 'inProgress'}
        sx={buttonStyle}
      >
        {getButtonLabel()}
      </Button>
      <br />
      <br />
      <br />
      <Button
        variant="contained"
        onClick={endGame}
        disabled={gameState.state === 'initial'}
        sx={buttonStyle}
        color={'error'}
      >
        End Game
      </Button>

      <Button variant="contained" onClick={resetGame} sx={buttonStyle} color={'error'}>
        Archive & Reset
      </Button>
    </div>
  );
};

export default Controls;
