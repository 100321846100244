import { BrowserRouter as Router, useRoutes } from 'react-router-dom';

// import Home from './Home';
// import BipolarGameComponent from './BipolarGame';
// import NumberGameComponent from './NumberGame';
import Game from './Game';
import Presenter from './Presenter';

import '../styles/App.scss';

const AppRouter = () => {
  const routes = useRoutes([
    { path: '/', element: <Game /> },
    { path: '/presenter', element: <Presenter /> },
    { path: '/results', element: <Presenter isReadOnly /> },
    // { path: '/bipolar', element: <BipolarGameComponent /> },
  ]);
  return routes;
};

const App = () => {
  return (
    <Router>
      <AppRouter />
    </Router>
  );
};

export default App;
