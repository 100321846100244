import React, { useState, useEffect, useContext } from 'react';
import io from 'socket.io-client';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import LoadingComponent from '../common/LoadingComponent';

import config from '../../config';
// console.log('slidesConfigData', slidesConfigData);

const PresenterOrAttendeeContext = React.createContext(null);

const UNIQUEATTENDEEID = 'UNIQUEATTENDEEID';
export const AttendeeAndPresenterProvider = ({ children }) => {
  const [connected, setConnected] = useState(false);
  const [socket, setSocket] = useState();
  const [gameState, setGameState] = useState({});
  const [questions, setQuestions] = useState({});
  const [questionIds, setQuestionIds] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [userAnswers, setUserAnswers] = useState({});
  const [attendeeId, setAttendeeId] = useState('123');

  const [prevQuestion, setPrevQuestion] = useState('initial');

  useEffect(() => {
    // we need to emit a get session
    const newSocket = io.connect(config.baseUrl);
    setSocket(newSocket);
    if (sessionStorage.getItem(UNIQUEATTENDEEID)) {
      setAttendeeId(sessionStorage.getItem(UNIQUEATTENDEEID));
    } else {
      const newAttendeeId = uuidv4();
      setAttendeeId(newAttendeeId);
      sessionStorage.setItem(UNIQUEATTENDEEID, newAttendeeId);
    }

    newSocket.on('connect', () => {
      console.log('connecting');
      newSocket.emit('getState');
      newSocket.emit('getQuestions');

      setConnected(true);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log('in socket useEffect', socket);
    if (socket) {
      socket.on('gameState', (gameStateData) => {
        setGameState(gameStateData);
      });

      socket.on('questions', (questionsData) => {
        // console.log('questionsData', questionsData);
        setQuestionIds(_.map(questionsData, 'id'));
        setQuestions(_.keyBy(questionsData, 'id'));
      });

      socket.on('currentQuestion', (currentQuestionId) => {
        // console.log('currentQuestionId', currentQuestionId);
        setCurrentQuestion(questions[currentQuestionId]);
      });

      socket.on('userAnswers', (userAnswers) => {
        // console.log('ZZZZZZ userAnswers', userAnswers);
        setUserAnswers(userAnswers);
      });

      socket.on('resetAnswers', () => {
        setUserAnswers({});
      });

      socket.on('disconnect', () => {
        setConnected(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  useEffect(() => {
    // console.log(
    //   'in gameState useEffect',
    //   'gamestate:',
    //   gameState,
    //   'questions',
    //   questions,
    //   'questionIds:',
    //   questionIds,
    //   'currentQuestion',
    //   currentQuestion
    // );
    if ((gameState.questionId && questions && !currentQuestion) || gameState.questionId !== currentQuestion?.id) {
      console.log('setting currentQuestion', gameState.questionId);
      setCurrentQuestion(questions[gameState.questionId]);
    }
  }, [gameState, questions, questionIds, currentQuestion]);

  const startGame = () => {
    socket.emit('startGame', { questionId: questionIds[0] });
  };

  const nextQuestion = () => {
    const nextQuestionId = questionIds[questionIds.indexOf(currentQuestion.id) + 1];
    setUserAnswers({});
    resetAnswers();
    socket.emit('nextQuestion', { nextQuestionId });
  };

  const resetAnswers = () => {
    socket.emit('resetAnswers');
  };

  const openQuestion = () => {
    socket.emit('openQuestion');
  };

  const closeQuestion = () => {
    // if its the last question we should jsut end the game
    if (questionIds.indexOf(currentQuestion.id) === questionIds.length - 1) {
      setPrevQuestion('initial');
      endGame();
    } else {
      setPrevQuestion(currentQuestion.id);
      socket.emit('closeQuestion');
    }
  };

  const submitResponse = (answerId, questionId) => {
    console.log('submitResponse', answerId, questionId, attendeeId);
    socket.emit('submitResponse', { answerId, questionId, attendeeId, category: currentQuestion.category });
  };

  const endGame = () => {
    setPrevQuestion('initial');
    // setUserAnswers({});
    resetAnswers();
    socket.emit('endGame');
  };

  const resetGame = () => {
    setPrevQuestion('initial');
    // setUserAnswers({});
    resetAnswers();
    socket.emit('resetGame');
  };

  if (connected) {
    return (
      <PresenterOrAttendeeContext.Provider
        value={{
          socket,
          gameState,
          questions,
          currentQuestion,
          startGame,
          nextQuestion,
          openQuestion,
          closeQuestion,
          prevQuestion,
          submitResponse,
          endGame,
          resetGame,
          userAnswers,
          attendeeId
        }}
      >
        {children}
      </PresenterOrAttendeeContext.Provider>
    );
  }
  return <LoadingComponent />;
};

export const usePresenterOrAttendee = () => {
  const store = useContext(PresenterOrAttendeeContext);
  return store;
};
