import { AttendeeAndPresenterProvider } from '../context/AttendeeAndPresenterProvider';
// import QuestionComponent from './QuestionComponent';

import GameComponent from './GameComponent';

const BipolarGameComponent = () => {
  return (
    <AttendeeAndPresenterProvider>
      <GameComponent />
    </AttendeeAndPresenterProvider>
  );
};

export default BipolarGameComponent;
