import React from 'react';

const CustomButton = ({ isCorrect, isSelected, text, onClick }) => {
  const getClass = () => {
    if (isSelected) {
      return isCorrect ? 'unbranded-correct-button' : 'unbranded-incorrect-button';
    }
    return '';
  };

  return (
    <button type="submit" className={`unbranded-button ${getClass()}`} onClick={onClick}>
      {text}
    </button>
  );
};

export default CustomButton;
