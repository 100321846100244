import { useEffect, useState } from 'react';

import { usePresenterOrAttendee } from '../context/AttendeeAndPresenterProvider';

import BipolarQuestionComponent from './BipolarQuestionComponent';
import NumberQuestionComponent from './NumberQuestionComponent';

const GameComponent = () => {
  const { currentQuestion, gameState, submitResponse, questions } = usePresenterOrAttendee();

  const [questionCategory, setQuestionCategory] = useState('bipolar');
  const [isIdleOrEnded, setIsIdleOrEnded] = useState(true);

  useEffect(() => {
    if (gameState.state === 'initial') {
      setQuestionCategory('bipolar');
    }

    if (
      gameState.state === 'initial' ||
      !gameState.state ||
      !currentQuestion ||
      ['closed', 'new'].includes(gameState.questionState) ||
      gameState.state === 'ended'
    ) {
      setIsIdleOrEnded(true);
    } else {
      setIsIdleOrEnded(false);
    }

    if (currentQuestion) {
      // console.log('questions', questions, currentQuestion.id);

      const nextQuestion = questions[currentQuestion.id + 1];
      if (gameState.questionState !== 'closed' && currentQuestion.category !== questionCategory) {
        // console.log('NOT CLOSED: currentQuestion.category', currentQuestion.category, currentQuestion);
        setQuestionCategory(currentQuestion.category.toLowerCase());
      } else if (gameState.questionState === 'closed' && nextQuestion && nextQuestion.category !== questionCategory) {
        // console.log(
        //   'CLOSED!!! nextQuestion.category',
        //   nextQuestion.category,
        //   questionCategory,
        //   nextQuestion,
        //   'xxxxxxx',
        //   currentQuestion
        // );
        setQuestionCategory(nextQuestion.category.toLowerCase());
      }
    }
  }, [gameState, currentQuestion, questionCategory, questions]);

  // useEffect(() => {
  //   if (currentQuestion && currentQuestion.category !== questionCategory) {
  //     setQuestionCategory(questions[currentQuestion.id + 1].category.toLowerCase());
  //   }
  //   if ()
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [currentQuestion]);

  // console.log('currentQuestion 1234567', currentQuestion);

  return questionCategory === 'bipolar' ? (
    <div className="unbranded" style={{ padding: '40px 25px' }}>
      <BipolarQuestionComponent
        isIdleOrEnded={isIdleOrEnded}
        currentQuestion={currentQuestion}
        gameState={gameState}
        submitResponse={submitResponse}
      />
    </div>
  ) : (
    <div className="branded" style={{ padding: '25px 25px 10px 25px' }}>
      <NumberQuestionComponent
        isIdleOrEnded={isIdleOrEnded}
        currentQuestion={currentQuestion}
        gameState={gameState}
        submitResponse={submitResponse}
      />
    </div>
  );
};

export default GameComponent;
